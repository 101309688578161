// vuex
import { createNamespacedHelpers } from "vuex";
const {
  mapState,
  mapGetters,
  mapActions,
  mapMutations
} = createNamespacedHelpers("project");

// mutationTypes
import { UPDATE_RECORD_INFO } from "@/store/shared/mutationTypes/record/record-mutation-types";

// mixins
import { dataTableDocumentsMixin } from "@/mixins/shared/documents/dataTableDocumentsMixin";

/**
 * dataTableProjectDocumentsMixin encapsulate project module dataTable Documents
 */
export const dataTableProjectDocumentsMixin = {
  mixins: [dataTableDocumentsMixin],
  computed: {
    ...mapState({
      /**
       * Selected record
       * Overwrite dataTableDocumentsMixin.record()
       * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
       */
      record: "record",

      /**
       * documents of selected category
       * Overwrite dataTableDocumentsMixin.items()
       * @return {{id:number, name:string, categoryId:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, flags:number, hasImage:boolean, owner:string, pages:number, recordTypeId:number, recordType:string, version:number, versionDate:string}[]}
       */
      items: "documents",

      /**
       * Selected document
       * Overwrite dataTableDocumentsMixin.currentDocument()
       * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, owner:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, version: number, versionDate: string, versionOwner: string, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: any}[], flyingFields: {id:number, sequence:number}[]}}
       */
      currentDocument: "document",

      /**
       * current selected Document Category
       * remarks: Overwrite Abstract computed dataTableDocumentsMixin.currentDocumentCategory
       * @return {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, fields: {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]}[]}
       */
      currentDocumentCategory: "documentCategory",

      /**
       * selected File Version
       */
      selectedFileVersion: "version",

      /**
       * selected File Version Category
       * remarks: Overwrite computed dataTableDocumentsMixin.selectedFileVersionCategory
       * @return {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, fields: {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]}[]}
       */
      selectedFileVersionCategory: "versionCategory"
    }),

    ...mapGetters({
      /**
       * Overwrite dataTableMixin.selectedModuleItemId()
       */
      selectedModuleItemId: "selectedModuleItemId",

      /**
       * Overwrite dataTableMixin.selectedModuleItemName()
       */
      selectedModuleItemName: "selectedModuleItemName"
    })
  },

  methods: {
    ...mapActions({
      /**
       * Overwrite base methods
       */
      setDocuments: "setDocuments",
      setDocument: "setDocument",
      downloadFile: "downloadFile",
      setFileDocuments: "setFileDocuments",
      setFileVersion: "setFileVersion",
      setDocumentChildren: "setDocumentChildren",
      checkOutFile: "checkOutFile"
    }),
    ...mapMutations({
      updateRecordInfo: UPDATE_RECORD_INFO
    })
  }
};
