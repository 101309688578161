<template>
  <base-table-layout
    :error="currentError"
    @alertVisibilityChanged="onAlertVisibilityChanged"
  >
    <!-- search -->
    <template v-slot:search v-if="visibleSearch">
      <search-text-field
        v-model="search"
        v-if="visibleSearch"
      ></search-text-field>
    </template>

    <!-- data Table -->
    <template v-slot:dataTable>
      <v-data-table
        v-model="selectedItems"
        :show-expand="showExpand"
        :show-select="true"
        :single-select="singleSelect"
        :item-key="itemKey"
        :headers="filteredHeaders"
        :items="items"
        :items-per-page="itemsPerPage"
        :multi-sort="multiSort"
        :search="search"
        :loading="isLoading"
        :loading-text="loadingText"
        :item-class="rowBackgroundClassName"
        @item-expanded="onItemExpanded"
        @click:row="showItemDetails"
        :no-data-text="getEmptyTableDataText"
        :custom-sort="customSort"
      >
        <template v-slot:footer.prepend>
          <!-- Report Button for selected records -->
          <base-tooltip-button
            v-if="visibleReportDetailAction"
            @click="openReportDialog"
          >
            {{ commandDetailReport }}
            <template v-slot:toolTip>
              {{ commandDetailReportTooltip }}
            </template>
          </base-tooltip-button>
        </template>

        <template v-slot:top>
          <!-- Data table Toolbar -->
          <layout-data-table-toolbar>
            <!-- Data Table Toolbar command buttons -->
            <template v-slot:commands>
              <!-- Show Record Details Dialog -->
              <record-details-dialog
                :record="editedItem"
                :category="editedItemCategory"
                :visible="visibleRecordDetailsDialog"
                @close="closeRecordDetailsDialog"
              >
              </record-details-dialog>

              <!-- Show Download File Dialog -->
              <download-file-dialog
                :record="downloadFileRecord"
                :visible="visibleDownloadFileDialog"
                :is-email="isEmail"
                @close="closeDownloadFileDialog"
              >
              </download-file-dialog>

              <!-- Show Print Options Dialog -->
              <print-file-dialog
                :record="downloadFileRecord"
                :visible="visiblePrintFileDialog"
                @close="closePrintFileDialog"
              >
              </print-file-dialog>

              <!-- Show Report Dialog -->
              <report-dialog
                :record-list="selectedItems"
                :search-headers="listHeaders"
                :visible="visibleReportDialog"
                :show-details="showRecordDetailsDialog"
                @close="closeReportDetailDialog"
              >
              </report-dialog>

              <!-- Document Editor Dialog -->
              <document-editor-dialog
                :id="editRecordId"
                :version="editRecordVersion"
                :visible="visibleDocumentEditorDialog"
                @update-record="onRecordChanged"
                @close="closeDocumentEditorDialog"
              ></document-editor-dialog>

              <!-- Document Editor Dialog -->
              <default-pdf-viewer-dialog
                :visible="visibleDefaultPdfViewerDialog"
                :record="viewedRecord"
                @onCheckout="recordCheckOut(true)"
                @close="closeDefaultPdfViewerDialog"
              ></default-pdf-viewer-dialog>
            </template>
          </layout-data-table-toolbar>
        </template>

        <!-- expanded-item -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container fluid>
              <v-card flat :loading="visibleLoadChildrenProgress(item)">
                <v-list dense>
                  <template v-for="child in getChildren(item)">
                    <v-list-item :key="`child-${child.id}`">
                      <!-- icon -->
                      <v-list-item-icon>
                        <record-badge-icon :record="child"></record-badge-icon>
                      </v-list-item-icon>
                      <!-- content -->
                      <v-list-item-content>
                        <v-list-item-title>
                          <document-link
                            :record="child"
                            v-on:click="showDocumentEditorDialog"
                            :use-default-pdf-viewer="true"
                            @openDefaultPdfViewer="
                              openDefaultPdfViewerDialog(child)
                            "
                          ></document-link>
                        </v-list-item-title>
                      </v-list-item-content>
                      <!-- action -->
                    </v-list-item>
                  </template>
                </v-list>
              </v-card>
            </v-container>
          </td>
        </template>

        <!-- v-slot:item.data-table-expand -->
        <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
          <base-tooltip-button
            :is-icon="true"
            v-if="showExpandIcon(item)"
            @click="expand(!isExpanded)"
          >
            <template v-slot:icon-name>
              {{ expandIcon(isExpanded) }}
            </template>
            <template v-slot:toolTip>
              {{ expandIconTooltip(item, isExpanded) }}
            </template>
          </base-tooltip-button>
        </template>

        <!-- Link to document viewer -->
        <template v-slot:item.name="{ item }">
          <document-link
            :record="item"
            v-on:click="showDocumentEditorDialog"
            :use-default-pdf-viewer="true"
            @openDefaultPdfViewer="openDefaultPdfViewerDialog(item)"
          ></document-link>
        </template>

        <!-- record pages -->
        <template v-slot:item.pageCount="{ item }">
          <v-chip v-if="item.isSingleFile">
            {{ item.pageCount }}
          </v-chip>
        </template>

        <!-- record type -->
        <template v-slot:item.recordType="{ item }">
          <record-badge-icon :record="item"></record-badge-icon>
        </template>

        <!-- record state -->
        <template v-slot:item.state="{ item }">
          <record-state-chip :record="item"></record-state-chip>
        </template>

        <!-- Actions -->
        <template v-slot:item.actions="{ item }">
          <base-drop-down-menu-button top ref="refDropDownMenuActions">
            <template v-slot:iconName></template>
            <template v-slot:iconMenuDownName>{{
              iconDotsHorizontal
            }}</template>
            <template v-slot:commands>
              <!-- show Print File Dialog -->
              <template v-if="visibleCommandPrint(item)">
                <base-menu-item
                  @click="showPrintFileDialog(item)"
                  v-if="isAllowedPrintWithAnnotateOptions(item)"
                >
                  <template v-slot:iconName>
                    {{ commandPrintIconName }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ tooltipPrintLabel(item) }}
                    </div>
                  </template>
                  {{ commandPrintLabel(item) }}
                </base-menu-item>
              </template>

              <!-- Show Download File Dialog -->
              <template v-if="visibleCommandDownload(item)">
                <base-menu-item @click="showDownloadFileDialog(item)">
                  <template v-slot:iconName>
                    {{ commandDownloadIconName }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ tooltipDownloadLabel(item) }}
                    </div>
                  </template>
                  {{ commandDownloadLabel(item) }}
                </base-menu-item>
              </template>

              <!-- Show Email File Dialog - remarks: Under Development only -->
              <base-menu-item
                v-if="visibleCommandEmail(item)"
                @click="onEmailFile(item)"
              >
                <template v-slot:iconName>
                  {{ commandEmailIconName }}
                </template>
                <template v-slot:tooltipContent>
                  <div>
                    {{ tooltipEmailLabel(item) }}
                  </div>
                </template>
                {{ commandEmailLabel }}
              </base-menu-item>

              <!-- view record details -->
              <base-menu-item @click="showRecordDetailsDialog(item)">
                <template v-slot:icon>
                  <record-badge-icon
                    :record="item"
                    :info-only="true"
                  ></record-badge-icon>
                </template>
                <template v-slot:tooltipContent>
                  <div>
                    {{ tooltipViewRecordDetailsLabel(item) }}
                  </div>
                </template>
                {{ commandViewRecordDetailsLabel(item) }}
              </base-menu-item>

              <!-- Select File/Record in Folder Tree -->
              <base-menu-item
                :to="folderModuleRecordRouteLocation(item)"
                :disabled="!canNavigateToFolderModuleRecord(item)"
              >
                <template v-slot:iconName>
                  {{ commandSelectRecordIconName }}
                </template>
                <template v-slot:tooltipContent>
                  <div>
                    {{ tooltipSelectRecordLabel(item) }}
                  </div>
                </template>
                {{ commandSelectRecordLabel(item) }}
              </base-menu-item>
            </template>
          </base-drop-down-menu-button>
        </template>
      </v-data-table>
    </template>

    <!-- snackbar -->
    <template v-slot:snackbar>
      <snackbar
        v-model="snackbarVisible"
        @close="closeSnackbar"
        :color="snackbarColor"
        :top="snackbarTop"
        >{{ snackbarText }}</snackbar
      >
    </template>
  </base-table-layout>
</template>

<script>
// model
import { recordType } from "@/model/record/recordModel";

// mixins
import { dataTableProjectDocumentsMixin } from "@/mixins/project/documents/dataTableProjectDocumentsMixin";
import { annotationOptionMixin } from "@/mixins/shared/documents/annotationOptionMixin";

export default {
  name: "ProjectDocuments",
  mixins: [dataTableProjectDocumentsMixin, annotationOptionMixin],
  methods: {
    /**
     * Overwrite dataTableMixin.setItems()
     * @returns {Promise<void>}
     */
    async setItems() {
      try {
        this.clearError();
        this.isLoading = true;

        try {
          if (this.record) {
            if (this.record.recordTypeId === recordType.FILE) {
              await this.setFileDocuments(this.record.id);
            } else {
              await this.setDocuments(this.record.id);
            }
          }
        } catch (e) {
          console.error(e);
        }
      } catch (e) {
        this.handleError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
